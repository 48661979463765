@import './src/scss/variables';

.games {
  padding: 1rem 0 0;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__content {
    //max-width: 900px;

    &-list {
	    display: flex;
	    flex-wrap: wrap;
	    justify-content: center;
	    text-align: center;
	    gap: 3.5rem 0;

      li {
        display: flex;
        justify-content: center;
        flex-basis: calc(33.33% - 3.5rem);

        a {
			    font-size: $font-size;
			    flex-direction: column;
          gap: .5rem;
        }

        span {
			    font-weight: 600;
        }
      }

			.fa-light {
			  //font-size: $font-size5;
			  font-size: $font-size7;
			  font-weight: 100 !important;
			}
    }
  }
}

.info-container {
	background: none !important;
	padding: 1rem 0 0;
}

@import 'media';
@import 'dark-theme';