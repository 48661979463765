@import './src/scss/variables';

.dark-theme {
	.games {
		&__content {
			&-list {

				.fa-light {
					color: $dark-color;
				}
			}
		}
	}
}